import domready from 'domready';
// import '../styles/styles.scss';// donne un preview du css en prod

const needsPolyfill = () =>
    typeof window.Intl === 'undefined' ||
    typeof window.Intl.PluralRules === 'undefined' ||
    typeof window.IntersectionObserver === 'undefined' ||
    typeof window.ResizeObserver === 'undefined';

let micromag = null;
const boot = () => {
    const render = (Micromag) => {
        if (micromag === null) {
            const propsEl = document.getElementById('app-props');
            const props = propsEl !== null ? JSON.parse(propsEl.innerHTML) || {} : {};
            micromag = new Micromag(props);
        }
        const appEl = document.getElementById('app');
        micromag.render(appEl);
    }

    const onLoaded = ({ default: Micromag }) => {
        render(Micromag);
    };

    if (needsPolyfill()) {
        import(/* webpackChunkName: "micromag-polyfill" */'./micromag.polyfill').then(onLoaded);
    } else {
        import(/* webpackChunkName: "micromag" */'./micromag').then(onLoaded);
    }
}

const ready = (document.readyState || 'loading') !== 'loading';
if (ready) {
    boot();
} else {
    domready(boot);
}
